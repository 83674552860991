import React from "react"
import PropTypes from "prop-types"

import styles from "./utils/elements.module.scss"

/**
 * @param {number} size integer, defaults to 2
 */

const Loading = ({ size, type, height, width }) => (
  <div
    className={styles[`${type || ""}loading`]}
    style={{
      fontSize: `${size || 2}rem`,
      height: `${height || 1.875}`,
      width: `${width || 1.875}`,
    }}
  />
)

Loading.propTypes = {
  size: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
}

export default Loading
